export default {
  // ? ==========================
  // ? ===== Multilocation ======
  // ? ==========================

  locationsPage: {
    '.logo': {
      maxWidth: ['175px', '', '200px', '300px']
    }
  },

  // ? ==========================
  // ? ======== Layout ==========
  // ? ==========================

  navigation: {
    fontFamily: 'Burford-Rustic',
    '.container': {
      padding: ['0.5rem', '0.5rem', '0.5rem', '0rem']
    },
    '.hamburger': {
      div: {
        backgroundColor: 'white'
      }
    },
    '& div:nth-child(4)': {
      justifyContent: 'center'
    },
    li: {
      a: {
        fontSize: ['2rem', '2.25rem', '2.5rem', '1.5rem'],
        letterSpacing: '-2px'
      }
    },

    '.logo': {
      top: ['', '', '', '82px'],
      img: {
        maxHeight: ['', '', '', '159px']
      }
    },
    '.locationSwitcher': {
      width: 'fit-content',

      right: ['', '', '', '0.75rem'],
      '.button': {
        fontSize: ['1rem', '1.25rem'],
        fontWeight: 'bold'
      }
    }
  },

  footer: {
    paddingBottom: ['5.5rem', '', '2.5rem'],
    '.multiButtonContainer': {
      a: {
        margin: '0.1rem'
      }
    },
    '.copyright': {
      fontSize: '0.9rem'
    },
    '.logoColumn': {
      flexDirection: 'row',
      flexWrap: 'wrap',
      maxWidth: '210px'
    },
    '.address': {
      maxWidth: '180px',
      textAlign: ['center', '', '', 'right']
    },
    img: { width: '100%' },
    '.footerprint': {
      justifyContent: 'center',
      alignItems: ['center', '', '', 'flex-end']
    },
    '.gonationLogo': {
      maxWidth: '120px'
    }
  },

  ctaWidget: {
    fontFamily: 'Burford-Rustic',
    a: {
      fontSize: ['1.25rem', , '1rem'],
      padding: ['0.75rem 0.5rem', '0.75rem']
    }
  },

  nextPage: {},

  // ? ==========================
  // ? ======== Homepage ========
  // ? ==========================

  homepageHero: {},

  homepageHeroShout: {
    backgroundColor: 'rgba(0, 0, 0, 0.55)',
    bottom: ['0.5rem', '1rem'],
    left: ['0.5rem', '1rem'],
    right: ['0.5rem', '1rem'],
    width: 'unset',

    '.title': {
      fontFamily: 'Burford-Rustic',
      textTransform: 'uppercase',
      fontWeight: '400',
      fontSize: ['1.4rem', , '1.6rem']
    },

    '.text': {
      textTransform: 'uppercase',
      fontSize: ['2rem', , '2.5rem'],
      lineHeight: '1',
      letterSpacing: '-2px',
      fontWeight: '400',
      marginTop: '0.25rem',
      marginBottom: '1rem'
    },
    '.date': {
      color: '#a3a3a3',
      fontSize: ['0.9rem', '', '', '1rem']
    }
  },

  homepageAbout: {
    letterSpacing: '0.5px',
    '.title': {
      lineHeight: '0.9',
      letterSpacing: '-2px',
      fontWeight: '400',
      fontSize: ['2.5rem', '3rem']
    },
    '.text': {
      lineHeight: ['1.4', '', '', '1.7']
    },
    span: {
      fontWeight: 'bold',
      border: 'solid 2px',
      borderColor: 'primary',
      '&:hover': {
        border: 'solid 2px',
        borderColor: 'primary',
        backgroundColor: 'transparent'
      }
    }
  },

  homepageMenu: {
    padding: '3rem 1rem',
    margin: '0 auto',
    maxWidth: '1200px',

    fontWeight: '400',
    '.title': {
      lineHeight: '0.9',
      letterSpacing: '-2px',
      fontWeight: '400',
      fontSize: ['2.5rem', '3rem']
    },
    '.subtitle': {
      lineHeight: '0.9',
      letterSpacing: '-2px',
      fontWeight: '400',
      fontSize: ['1.75rem', '2rem']
    },

    '.text': {
      lineHeight: ['1.4', '', '', '1.7']
    }
  },

  homepageSocialSection: {
    '.title': {
      fontWeight: 'normal'
    }
  },

  homepageSectionNavLinks: {
    h2: {
      fontWeight: '400',
      lineHeight: '0.8',
      fontSize: '1.75rem',
      right: 'unset'
    }
  },

  homepageLocationSection: {
    h2: {
      fontWeight: '400',
      lineHeight: '0.9',
      letterSpacing: '-2px'
    },
    '.button': {
      width: '100px',
      alignItems: 'center',
      fontSize: '1.5rem'
    }
  },

  homepageEventsTitle: {},
  homepageEvents: {},

  // ? ==========================
  // ? ======= About Page =======
  // ? ==========================

  aboutpageAbout: {
    letterSpacing: '0.5px',
    lineHeight: ['1.4', '', '', '1.7']
  },

  // ? ==========================
  // ? ======= Menu Page ========
  // ? ==========================

  menu: {
    color: 'black',
    maxWidth: 'unset',
    padding: '0rem 1rem',
    backgroundColor: 'white',
    '.innerMenuContainer': {
      maxWidth: '1200px',
      margin: '0 auto'
    },
    '.menuSectionTitle': {
      fontWeight: '400',
      fontSize: '2.75rem',
      margin: '0rem 0.5rem 0.5rem',
      letterSpacing: '-3px'
    },
    '.menuSectionDescription': {
      fontSize: '1.1rem',
      color: '#4a4a4a'
    },
    '.menuItemContainerImgActive,.menuItemContainer': {
      border: 'solid 1px #bbbbbb',
      margin: ['0.25rem', '', '0.5rem']
    },
    '.menuItemName': {
      fontSize: '1.4rem'
    },
    '.menuItemDescription': {
      fontSize: '1rem',
      color: '#383838'
    }
  },

  // ? ==========================
  // ? ====== Gallery Page ======
  // ? ==========================

  gallery: {
    padding: '1rem',
    '.albumsCTA': {
      alignItems: 'flex-end',
      justifyContent: 'flex-start',
      padding: '0.5rem',
      textAlign: 'left',
      width: ['unset', 'unset', 'unset', 'unset'],
      minWidth: ['25%'],
      flexGrow: '1'
    },
    '.albumTitle': {
      textShadow: 'none',
      fontWeight: '400',
      fontSize: '1.75rem',
      background: 'rgba(0,0,0,0.55)',
      padding: '0.5rem',
      textAlign: 'left',
      letterSpacing: '-2px'
    }
  },

  // ? ==========================
  // ? ====== Contact Page ======
  // ? ==========================

  contactForm: {
    h2: {
      fontWeight: '400',
      fontSize: ['3rem', '4rem', '5rem'],
      letterSpacing: ['-3px', '-4px', '-5px']
    }
  }
}
